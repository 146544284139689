import React, { useState, useEffect } from "react";
import styles from "./ConstructionModal.module.css";

const ConstructionModal = ({ onClose }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const targetDate = new Date();
    targetDate.setDate(targetDate.getDate() + 25); // 3 weeks from now

    const interval = setInterval(() => {
      const now = new Date();
      const difference = targetDate - now;

      setTimeLeft({
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      });

      if (difference < 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <h2>Website under construction</h2>
        <div id={styles.countdown}>
          <p>Going Live in</p>
          {timeLeft.days} days, {timeLeft.hours} hrs, {timeLeft.minutes} mins,{" "}
          {timeLeft.seconds} secs
        </div>
        <button onClick={onClose}>I still want to have a look 🙂</button>
      </div>
    </div>
  );
};

export default ConstructionModal;
