import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import DonatePage from "./Pages/DonatePage";
import ConstructionModal from "./Components/ConstructionModal";

function App() {
  const [showModal, setShowModal] = useState(true);

  const handleModalClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    // Optionally, you can also implement logic here to determine
    // whether to show the modal based on certain conditions
  }, []);

  return (
    <Router>
      <div className="App">
        {showModal && <ConstructionModal onClose={handleModalClose} />}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/donate" element={<DonatePage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
